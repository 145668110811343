var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.crLoading,
          expression: "crLoading"
        }
      ],
      staticClass: "compare-records"
    },
    [
      _vm.records.length <= 0
        ? _c("div", { staticClass: "compare-records-none" }, [
            _c("span", [_vm._v("暂无记录")])
          ])
        : _vm._l(_vm.records, function(item) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "tags",
                    rawName: "v-tags",
                    value: { text: item.compareStatusName, right: "100px" },
                    expression: "{text:item.compareStatusName, right: '100px'}"
                  }
                ],
                key: item.recheckId,
                ref: "scroll",
                refInFor: true,
                staticClass: "compare-record-parent",
                on: {
                  click: function($event) {
                    return _vm.changeRecheckId(item.recheckId)
                  }
                }
              },
              [
                _c("div", { staticClass: "compare-record" }, [
                  _c("div", { staticClass: "compare-record-detail" }, [
                    _c("span", { staticClass: "item" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("基础版本: ")
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(_vm._s(item.baseVersionName))
                      ])
                    ]),
                    _c("span", { staticClass: "item" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("对比版本: ")
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(_vm._s(item.targetVersionName))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "compare-record-detail" }, [
                    _c("span", { staticClass: "item" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("对比人: ")
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(_vm._s(item.createUserRealName))
                      ])
                    ]),
                    _c("span", { staticClass: "item" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("对比时间: ")
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(_vm._s(item.finishDate))
                      ])
                    ])
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "compare-record-recheck" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "small",
                          loading: !!item.loading,
                          disabled: _vm.shouldBeDisable(item.compareStatusName)
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.recheck(item)
                          }
                        }
                      },
                      [_vm._v("重启复核")]
                    )
                  ],
                  1
                )
              ]
            )
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }