var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "v-tag", style: { right: _vm.rightBias } }, [
    _c(
      "svg",
      { attrs: { width: "50", height: "50", viewBox: "0 0 400 400" } },
      [
        _c("circle", {
          attrs: {
            cx: "200",
            cy: "200",
            r: "200",
            fill: "#e9faf4",
            stroke: "#8fe2c6"
          }
        })
      ]
    ),
    _c("span", { staticClass: "textSpan" }, [_vm._v(_vm._s(_vm.text))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }