import _toConsumableArray from "F:/@company/lzz-productcheck-front/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * 数组去重
 */
function unique(arr) {
  return _toConsumableArray(new Set(arr));
}

export default {
  data: function data() {
    return {
      filterOptions: {
        specName: '',
        problemTypeName: '',
        problemStatusName: '',
        checkUserName: ''
      }
    };
  },
  props: {
    meta: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  computed: {
    major_options: function major_options() {
      return unique(this.meta.map(function (item) {
        return item.specName;
      }));
    },
    ps_options: function ps_options() {
      return unique(this.meta.map(function (item) {
        return item.problemStatusName;
      }));
    },
    type_options: function type_options() {
      return unique(this.meta.map(function (item) {
        return item.problemTypeName;
      }));
    },
    user_options: function user_options() {
      return unique(this.meta.map(function (item) {
        return item.checkUserName;
      }));
    }
  },
  methods: {
    confirmFilter: function confirmFilter() {
      this.$emit('itemsFilter', this.filterOptions);
    },
    // 重置筛选选项
    reset: function reset() {
      this.filterOptions = {
        specName: '',
        problemTypeName: '',
        problemStatusName: '',
        checkUserName: ''
      };
    }
  }
};