//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {
          compareStatusName: '',
          problemStatusName: '',
          specName: '',
          modifyParty: '',
          finishDate: '',
          detailId: ''
        };
      }
    }
  },
  methods: {
    getStyleOfStatusName: function getStyleOfStatusName(status) {
      var statusMap = {
        '已修改': 'already',
        '待修改': 'wait',
        '已复核': 'finish',
        '有修改': 'finish',
        '无法对比': 'wait'
      };
      return statusMap[status] || statusMap[2];
    }
  }
};