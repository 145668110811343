var isDev = process.env.NODE_ENV === 'development';
export var cmpRecord = isDev ? [{
  title: '成本配置优化',
  status: '待修改',
  major: '弱电',
  modifier: '精装设计院',
  completeTime: '2021-09-11',
  compareResult: '无法对比'
}, {
  title: '成本配置优化',
  status: '待修改',
  major: '弱电',
  modifier: '精装设计院',
  completeTime: '2021-09-11',
  compareResult: '无法对比'
}, {
  title: '成本配置优化',
  status: '待修改',
  major: '弱电',
  modifier: '精装设计院',
  completeTime: '2021-09-11',
  compareResult: '无法对比'
}, {
  title: '成本配置优化',
  status: '待修改',
  major: '弱电',
  modifier: '精装设计院',
  completeTime: '2021-09-11',
  compareResult: '无法对比'
}, {
  title: '成本配置优化',
  status: '待修改',
  major: '弱电',
  modifier: '精装设计院',
  completeTime: '2021-09-11',
  compareResult: '无法对比'
}] : [];
export var cr = {
  totalProblemNum: 20,
  problemFileNum: 10,
  unableFileNum: 0,
  details: [{
    compareStatusName: '可以对比',
    problemStatusName: '已修改',
    specName: '弱电',
    modifyParty: '精装设计院',
    finishDate: '2020-11-11',
    detailId: '001'
  }, {
    compareStatusName: '可以对比',
    problemStatusName: '已修改',
    specName: '弱电',
    modifyParty: '精装设计院',
    finishDate: '2020-11-11',
    detailId: '002'
  }, {
    compareStatusName: '可以对比',
    problemStatusName: '无法对比',
    specName: '弱电',
    modifyParty: '精装设计院',
    finishDate: '2020-11-11',
    detailId: '003'
  }, {
    compareStatusName: '可以对比',
    problemStatusName: '无法对比',
    specName: '弱电',
    modifyParty: '精装设计院',
    finishDate: '2020-11-11',
    detailId: '004'
  }, {
    compareStatusName: '可以对比',
    problemStatusName: '无法对比',
    specName: '弱电',
    modifyParty: '精装设计院',
    finishDate: '2020-11-11',
    detailId: '005'
  }, {
    compareStatusName: '可以对比',
    problemStatusName: '无法对比',
    specName: '弱电',
    modifyParty: '精装设计院',
    finishDate: '2020-11-11',
    detailId: '006'
  }, {
    compareStatusName: '可以对比',
    problemStatusName: '无法对比',
    specName: '弱电',
    modifyParty: '精装设计院',
    finishDate: '2020-11-11',
    detailId: '007'
  }]
};