import tag from '../components/tag.vue';
import Vue from 'vue';

function addTags(parentNode, props) {
  parentNode.style.position = 'relative';
  var t = new (Vue.extend(tag))({
    propsData: props
  }).$mount();
  parentNode.appendChild(t.$el);
}

var tags = {
  bind: function bind(el, binding) {
    addTags(el, binding.value);
  }
};
export default tags;