var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "nav" },
      [
        _c(
          "el-tabs",
          {
            staticClass: "el-tabs--maiaUnderline",
            on: { "tab-click": _vm.detailClick },
            model: {
              value: _vm.activeNav,
              callback: function($$v) {
                _vm.activeNav = $$v
              },
              expression: "activeNav"
            }
          },
          _vm._l(_vm.detailList, function(item, index) {
            return _c("el-tab-pane", {
              key: index,
              attrs: { label: item, name: index + "" }
            })
          }),
          1
        )
      ],
      1
    ),
    !_vm.hasData
      ? _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "nodata" }, [_vm._v("暂无数据")])
        ])
      : _c(
          "div",
          { staticClass: "content" },
          [
            _vm.activeNav == "0"
              ? _c(
                  "div",
                  { staticClass: "detail" },
                  [
                    _c(
                      "el-form",
                      {
                        staticClass: "detailShow",
                        attrs: { inline: "", "label-width": "100px" }
                      },
                      [
                        _c("el-form-item", { attrs: { label: "问题状态:" } }, [
                          _vm._v(" " + _vm._s(_vm.tabsDataSafe.queStatus) + " ")
                        ]),
                        _c("el-form-item", { attrs: { label: "专业:" } }, [
                          _vm._v(" " + _vm._s(_vm.tabsDataSafe.specName) + " ")
                        ]),
                        _c("el-form-item", { attrs: { label: "跨专业:" } }, [
                          _vm._v(" " + _vm._s(_vm.tabsDataSafe.multiSpec) + " ")
                        ]),
                        _c("el-form-item", { attrs: { label: "问题类型:" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.tabsDataSafe.queTypeName) + " "
                          )
                        ]),
                        _c("el-form-item", { attrs: { label: "问题等级:" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.tabsDataSafe.queFirstLevelName) +
                              " "
                          )
                        ]),
                        _c("el-form-item", { attrs: { label: "修改方:" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.tabsDataSafe.modifyPartyName) + " "
                          )
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "要求完成时间:" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.tabsDataSafe.requireFinishTime) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "queDesc",
                            attrs: { label: "问题描述:" }
                          },
                          [_vm._v(" " + _vm._s(_vm.tabsDataSafe.queDesc) + " ")]
                        )
                      ],
                      1
                    ),
                    _vm.isNewList
                      ? _c("div", { staticClass: "queStatusBtn" }, [
                          _c(
                            "div",
                            { staticClass: "inner-layout__queStatusBtn" },
                            [
                              _c(
                                "div",
                                { staticClass: "dealData" },
                                [
                                  _c("span", { staticClass: "mr8" }, [
                                    _vm._v("问题状态:")
                                  ]),
                                  _vm._l(
                                    (_vm.dicArray.statusList || []).filter(
                                      function(i) {
                                        return i.cnname !== "已复核"
                                      }
                                    ),
                                    function(item) {
                                      return _c(
                                        "el-button",
                                        {
                                          key: item.id,
                                          attrs: {
                                            type: "maiaPlain",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.changeStatus(item)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(item.cnname))]
                                      )
                                    }
                                  )
                                ],
                                2
                              ),
                              _c(
                                "div",
                                { staticClass: "dealData" },
                                [
                                  _c("span", { staticClass: "mr8" }, [
                                    _vm._v("复核状态:")
                                  ]),
                                  _vm._l(
                                    (_vm.dicArray.statusList || []).filter(
                                      function(i) {
                                        return i.cnname === "已复核"
                                      }
                                    ),
                                    function(item) {
                                      return _c(
                                        "el-button",
                                        {
                                          key: item.id,
                                          attrs: {
                                            type: "maiaPlain",
                                            size: "small"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.changeStatus(item)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(item.cnname))]
                                      )
                                    }
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "maiaPlain",
                                        size: "small"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.pushProblem()
                                        }
                                      }
                                    },
                                    [_vm._v("推送至比对版本")]
                                  )
                                ],
                                2
                              ),
                              _c(
                                "div",
                                { staticClass: "auto-to-next" },
                                [
                                  _c("el-checkbox", {
                                    attrs: { label: "自动跳转到下一条问题" },
                                    model: {
                                      value: _vm.autoToNext,
                                      callback: function($$v) {
                                        _vm.autoToNext = $$v
                                      },
                                      expression: "autoToNext"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm.activeNav == "1"
              ? _c("reply", {
                  ref: "reply",
                  attrs: {
                    type: "2D",
                    isNewList: _vm.isNewList,
                    id: _vm.activeId
                  },
                  on: { resetList: _vm.resetList }
                })
              : _vm.activeNav == "2"
              ? _c("record", {
                  ref: "record",
                  attrs: {
                    type: "2D",
                    isNewList: _vm.isNewList,
                    id: _vm.activeId
                  }
                })
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }