//
//
//
//
//
//
//
//
//
//
export default {
  name: 'v-tag',
  props: {
    text: {
      type: String,
      required: true
    },
    right: {
      type: String,
      required: false
    }
  },
  computed: {
    rightBias: function rightBias() {
      return this.right || '80px';
    }
  }
};