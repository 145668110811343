var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "issueCard", attrs: { id: _vm.data.detailId } },
    [
      _c("div", { staticClass: "issueCard-header" }, [
        _c("span", { staticClass: "title" }, [
          _c(
            "span",
            {
              class: [
                "title-status",
                _vm.getStyleOfStatusName(_vm.data.problemStatusName)
              ]
            },
            [_vm._v("[" + _vm._s(_vm.data.problemStatusName) + "]")]
          ),
          _vm._v(_vm._s(_vm.data.title))
        ]),
        _c(
          "span",
          {
            class: [
              "cmpRes",
              _vm.getStyleOfStatusName(_vm.data.compareStatusName)
            ]
          },
          [_vm._v("[" + _vm._s(_vm.data.compareStatusName) + "]")]
        )
      ]),
      _c("div", { staticClass: "issueCard-content" }, [
        _c("div", { staticClass: "contentLine" }, [
          _c("span", [
            _c("span", { staticClass: "label" }, [_vm._v("状态:")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.data.problemStatusName))])
          ]),
          _c("span", [
            _c("span", { staticClass: "label" }, [_vm._v("修改方:")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.data.modifyParty))])
          ])
        ]),
        _c("div", { staticClass: "contentLine" }, [
          _c("span", [
            _c("span", { staticClass: "label" }, [_vm._v("专业:")]),
            _c("span", [_vm._v(_vm._s(_vm.data.specName))])
          ]),
          _c("span", [
            _c("span", { staticClass: "label" }, [_vm._v("完成时间:")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.data.finishDate))])
          ])
        ]),
        _c("div", { staticClass: "describe" }, [
          _c(
            "span",
            { staticClass: "label", staticStyle: { "margin-right": "2px" } },
            [_vm._v("问题描述:")]
          ),
          _vm._v(" " + _vm._s(_vm.data.problemDesc || "") + " ")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }