var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticClass: "box-card" }, [
    _c(
      "div",
      { staticClass: "box-card-content" },
      [
        _c("span", [_vm._v("专业")]),
        _c(
          "el-select",
          {
            attrs: { placeholder: "请选择" },
            model: {
              value: _vm.filterOptions.specName,
              callback: function($$v) {
                _vm.$set(_vm.filterOptions, "specName", $$v)
              },
              expression: "filterOptions.specName"
            }
          },
          _vm._l(_vm.major_options, function(item) {
            return _c("el-option", {
              key: item,
              attrs: { label: item, value: item }
            })
          }),
          1
        ),
        _c("span", [_vm._v("问题类型")]),
        _c(
          "el-select",
          {
            attrs: { placeholder: "请选择" },
            model: {
              value: _vm.filterOptions.problemTypeName,
              callback: function($$v) {
                _vm.$set(_vm.filterOptions, "problemTypeName", $$v)
              },
              expression: "filterOptions.problemTypeName"
            }
          },
          _vm._l(_vm.type_options, function(item) {
            return _c("el-option", {
              key: item,
              attrs: { label: item, value: item }
            })
          }),
          1
        ),
        _c("span", [_vm._v("问题状态")]),
        _c(
          "el-select",
          {
            attrs: { placeholder: "请选择" },
            model: {
              value: _vm.filterOptions.problemStatusName,
              callback: function($$v) {
                _vm.$set(_vm.filterOptions, "problemStatusName", $$v)
              },
              expression: "filterOptions.problemStatusName"
            }
          },
          _vm._l(_vm.ps_options, function(item) {
            return _c("el-option", {
              key: item,
              attrs: { label: item, value: item }
            })
          }),
          1
        ),
        _c("span", [_vm._v("审查人")]),
        _c(
          "el-select",
          {
            attrs: { placeholder: "请选择" },
            model: {
              value: _vm.filterOptions.checkUserName,
              callback: function($$v) {
                _vm.$set(_vm.filterOptions, "checkUserName", $$v)
              },
              expression: "filterOptions.checkUserName"
            }
          },
          _vm._l(_vm.user_options, function(item) {
            return _c("el-option", {
              key: item,
              attrs: { label: item, value: item }
            })
          }),
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "actionFooter" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "maiaPlain", size: "small" },
            on: { click: _vm.reset }
          },
          [_vm._v("重置")]
        ),
        _c(
          "el-button",
          {
            attrs: { type: "maiaPrimary", size: "small" },
            on: { click: _vm.confirmFilter }
          },
          [_vm._v("确认")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }