var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "issueList" },
    [
      _c("div", { staticClass: "issueList-header" }, [
        _c("span", { staticClass: "totalNumber" }, [
          _vm._v("问题列表(" + _vm._s(_vm.details.length || 0) + ")")
        ]),
        _c(
          "span",
          [
            _c(
              "el-checkbox",
              {
                model: {
                  value: _vm.showModified,
                  callback: function($$v) {
                    _vm.showModified = $$v
                  },
                  expression: "showModified"
                }
              },
              [_vm._v("只显示有修改问题")]
            )
          ],
          1
        ),
        _c("span", {
          staticClass: " filter ma-r-funnel2 color1",
          on: { click: _vm.switchFilter }
        })
      ]),
      _c(
        "div",
        { staticClass: "issueList-content" },
        _vm._l(_vm.details, function(item) {
          return _c("issue-card", {
            key: item.detailId,
            class: _vm.currentClickId === item.detailId ? "card-selectd" : "",
            attrs: { data: item },
            nativeOn: {
              click: function($event) {
                return _vm.currentCard(item.detailId)
              }
            }
          })
        }),
        1
      ),
      _c("filter-card", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.filter,
            expression: "filter"
          }
        ],
        attrs: { meta: _vm.details },
        on: { itemsFilter: _vm.setFilterOptions }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }